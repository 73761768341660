<template>
    <div class="uk-width-1-1">
        <template v-if="$can('READ', 'worker-tax-deduction')">
            <div class="uk-width-1-1 uk-margin-remove">
                <!-- Header -->
                <div class="uk-flex uk-flex-between uk-flex-middle gap-10px">
                    <p class="uk-text-left uk-text-bold">Tax Deduction Proof Details</p>
                    <div v-if="$can('EDIT', 'worker-tax-deduction')" class="uk-width-1-2">
                        <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                            <div class="uk-width-1-2">
                                <button
                                    v-if="downloadTaxBatchTemplateLoading"
                                    type="button"
                                    class="uk-button uk-border-rounded uk-width-expand"
                                    disabled
                                >
                                    <span><div uk-spinner="ratio: 0.5"></div></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else
                                    type="button"
                                    class="uk-button uk-border-rounded uk-width-expand uk-text-center uk-position-relative myrobin-border-blue myrobin-color-blue myrobin-background-white"
                                    @click="doDownloadTaxBatchTemplate"
                                >
                                    <img
                                        class="uk-position-center-left uk-margin-small-left"
                                        :src="`${images}/download-outline-blue.svg`"
                                        alt="Upload icon"
                                    >
                                    <p class="uk-margin-remove">Download</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End header -->

                <!-- Tax component table list -->
                <div class="uk-width-1-1 uk-card uk-card-default uk-margin-top">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-large uk-table-middle uk-table-striped">
                            <thead>
                                <tr>
                                    <th class="uk-width-1-1 uk-text-left">Field Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(field, index) in fieldList" :key="index">
                                    <td class="uk-width-1-1 uk-text-left">{{ field }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- End of tax component table list -->
            </div>
        </template>

        <!-- Handler when user can't access -->
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
        <!-- End handler when user can't access -->
    </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    name: 'TaxDeductionComponent',
    props: {
        downloadTaxBatchTemplateLoading: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            fieldList: [
                'Email',
                'Nomor Bukti Potong',
                'NPWP',
                'NIK',
                'Nama',
                'Alamat',
                'WP Luar Negeri',
                'Kode Negara',
                'Kode Pajak',
                'Jumlah Bruto',
                'Jumlah DPP',
                'Tanpa NPWP',
                'Tarif',
                'Jumlah PPh',
                'Tanggal Bukti Potong',
            ]
        };
    },
    methods: {
        doDownloadTaxBatchTemplate() {
            this.$emit('download-tax-batch-template');
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}

.myrobin-color-blue {
    color: #0275D8;
}
.myrobin-border-blue {
    border: 1px solid #0275D8;
}
.gap-10px {
    gap: 10px;
}
.gap-45px {
    gap: 45px;
}
</style>
